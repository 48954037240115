body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gg-log-in {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 6px;
  height: 16px;
  border: 2px solid;
  border-left: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-right: -10px
}
.gg-log-in::after,
.gg-log-in::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}
.gg-log-in::after {
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  left: -8px;
  bottom: 2px
}
.gg-log-in::before {
  border-radius: 3px;
  width: 10px;
  height: 2px;
  background: currentColor;
  left: -11px;
  bottom: 5px
}

/* edited for xl flavour from https://github.com/tachyons-css/tachyons/blob/master/src/_max-widths.css */
@media (min-width: 90em) {
  .w1-xl {      width: 1rem; }
  .w2-xl {      width: 2rem; }
  .w3-xl {      width: 4rem; }
  .w4-xl {      width: 8rem; }
  .w5-xl {      width: 16rem; }
  .w-10-xl {    width:  10%; }
  .w-20-xl {    width:  20%; }
  .w-25-xl {    width:  25%; }
  .w-30-xl {    width:  30%; }
  .w-33-xl {    width:  33%; }
  .w-34-xl {    width:  34%; }
  .w-40-xl {    width:  40%; }
  .w-50-xl {    width:  50%; }
  .w-60-xl {    width:  60%; }
  .w-70-xl {    width:  70%; }
  .w-75-xl {    width:  75%; }
  .w-80-xl {    width:  80%; }
  .w-90-xl {    width:  90%; }
  .w-100-xl {   width: 100%; }
  .w-third-xl { width: calc(100% / 3); }
  .w-two-thirds-xl { width: calc(100% / 1.5); }
  .w-auto-xl {    width: auto; }
}
